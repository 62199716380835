<sc-dashboard-layout class="xxwide">
  <div class="account-settings-header sticky-blur-header">
    <div class="header-content">
      <ng-select
        *ngIf="(role$ | async) >= roleLimits.SHOW_READ"
        [(ngModel)]="selectedShow"
        aria-label="Select Show"
        (change)="changeShow()"
        [disabled]="!!editingSession?.recordingStarted"
        [clearable]="false"
        placeholder="Select a Show"
        [tooltip]="editingSession?.recordingStarted ? 'Session has recordings, unable to change show' : 'Select a show for the session'"
        appearance="outline"
        notFoundText=""
        appendTo="body">
        <ng-option *ngFor="let show of availableShows$ | async" [value]="show">
          <sc-show-list-item [show]="show"></sc-show-list-item>
        </ng-option>
      </ng-select>
    </div>

    <div class="header-buttons">
      <div class="save-status" [class.bounce]="savingSession">
        <span *ngIf="!sessionLoaded; else savestatus">Loading...</span>
        <ng-template #savestatus>
          <span *ngIf="savingSession">Saving...</span>
          <span *ngIf="!savingSession && editingSession">
            Saved <ion-icon src="assets/icons/24px/check-circle.svg"></ion-icon>
          </span>
        </ng-template>
      </div>
      <ion-button
        *ngIf="
              editing ? (role$ | async) >= roleLimits.SESSION_UPDATE : (role$ | async) >= roleLimits.SESSION_CREATE
              "
        (click)="sendEmails()"
        aria-label="Send Emails"
        [disabled]="!selectedShow || !sessionTitle || sendingEmails || savingSession || (!viewerMembers.length && !stageMembers.length)"
        class="primary send-emails-button">
        Send Emails
      </ion-button>
      <ion-button
        *ngIf="sessionTitle && selectedShow"
        [disabled]="savingSession"
        (click)="done()"
        fill="clear"
        class="primary"
        id="done-button">
        Done
      </ion-button>
      <ion-button *ngIf="!sessionTitle || !selectedShow" (click)="done()" fill="clear" class="text" id="cancel-button">
        Cancel
      </ion-button>
    </div>
  </div>

  <div class="account-settings-content">
    <div class="session-column">
      <form>
        <ion-item class="input-large ion-margin-bottom" lines="none" id="sessionTitle">
          <ion-label position="stacked"> Title </ion-label>

          <ion-input
            [(ngModel)]="sessionTitle"
            name="sessionTitle"
            #titleCtrl="ngModel"
            scAutoFocus
            placeholder="Title"
            type="text"
            (ionBlur)="onTitleBlur()"
            required
            maxlength="100"
            minlength="1">
            <div class="validation-error" *ngIf="titleCtrl.invalid && titleCtrl.touched">
              <p *ngIf="titleCtrl.errors?.['required']">Title is required</p>
              <p *ngIf="titleCtrl.errors?.['minlength']">
                Minimum title length is {{ titleCtrl.errors.minlength.requiredLength }}, you only have {{
                titleCtrl.errors.minlength.actualLength }}
              </p>
            </div>
          </ion-input>
        </ion-item>
      </form>

      <ion-item class="menu-large ion-margin-bottom" lines="none">
        <ion-label color="lighter" position="stacked" class="date-input-label">Date</ion-label>
        <sc-input-date
          #inputDate
          [initWithToday]="true"
          aria-label="Session Date"
          [required]="true"
          format="l"
          [timezone]="timezone"
          (set)="dateSet($event)"></sc-input-date>
      </ion-item>

      <div class="time-container">
        <ion-item class="menu-large ion-margin-bottom" lines="none" id="startTime">
          <ion-label position="stacked" class="date-input-label"> Start Time </ion-label>

          <sc-input-date
            #inputStartTime
            [initWithToday]="true"
            aria-label="Session Start Time"
            [required]="true"
            format="LT"
            [timezone]="timezone"
            (set)="startTimeSet($event)"></sc-input-date>
        </ion-item>

        <ion-item class="menu-large ion-margin-bottom" lines="none" id="endTime">
          <ion-label position="stacked" class="date-input-label">
            End Time
            <span *ngIf="sessionDuration">({{sessionDurationString}})</span>
          </ion-label>

          <sc-input-date
            #inputEndTime
            aria-label="Session End Time"
            [required]="true"
            format="LT"
            [timezone]="timezone"
            (set)="endTimeSet($event)"></sc-input-date>
        </ion-item>
      </div>

      <ion-item class="input-large ion-margin-bottom" lines="none" id="input-timezone">
        <ion-label position="stacked"> Timezone </ion-label>
        <!-- (change)="setDefaultTimezone($event)" -->
        <ng-select
          placeholder="Timezone"
          [items]="timezones"
          aria-label="Select timezone for the session."
          bindLabel="name"
          [clearable]="false"
          notFoundText=""
          appendTo="body"
          [(ngModel)]="timezone"
          notFoundText=""
          name="timezone">
        </ng-select>
      </ion-item>

      <ion-label position="stacked"> Recording Format </ion-label>

      <!-- Segment with anchors -->
      <ion-segment mode="md" (ionChange)="formatChanged($event)" [ngModel]="videoEnabled ? 'video' : 'audio'">
        <ion-segment-button
          value="video"
          [upgrade]="!(plan$ | async)?.videoRecording"
          [tooltip]="!(plan$ | async)?.videoRecording ? 'Upgrade To Record Video & Audio' : ''">
          <span>Video<span class="hide-mobile"> & Audio</span></span>
        </ion-segment-button>

        <ion-segment-button value="audio">
          <span>Audio<span class="hide-mobile"> Only</span></span>
        </ion-segment-button>
      </ion-segment>

      <ion-item lines="none" class="recording-quality">
        <ion-label *ngIf="(plan$ | async)?.videoUHD">
          <ion-badge color="warning" tooltip="4K Recordings are currently in beta!">BETA</ion-badge>
          Maximize Recording Quality
          <sc-learn-more topic="qualityStandards"></sc-learn-more>
        </ion-label>

        <ion-button
          *ngIf="!(plan$ | async)?.videoUHD"
          [upgrade]="!(plan$ | async)?.videoUHD"
          [class.disabled]="!(plan$ | async)?.videoUHD"
          tooltip="Upgrade To Unlock"
          [routerLink]="['/account/subscription']"
          oncontextmenu="false"
          class="primary upgrade"
          expand="block">
          Upgrade to enable 4K!
        </ion-button>
        <ion-toggle
          *ngIf="(plan$ | async)?.videoUHD"
          [(ngModel)]="maxQuality"
          (ionChange)="recordingQualityChanged($event)"></ion-toggle>
      </ion-item>
    </div>
    <div class="participants-column">
      <h4 class="ion-no-margin medium">The Stage</h4>

      <p class="ion-no-margin sub-text">
        <small>
          Select a show member or enter email to invite guests. Only these participants will be recorded and have
          microphone / camera access.
        </small>
      </p>

      <ion-item lines="none" class="copy-link" (click)="selectInviteLink($event)">
        <ion-icon src="assets/icons/24px/link.svg"></ion-icon>

        <div class="invite-link-url">{{onStageInviteLink}}</div>
        <div *ngIf="!onStageInviteLink" class="placeholder">
          Add Title <span class="ion-hide-sm-down">to generate link</span>
        </div>
        <!-- <ion-input
                [value]="onStageInviteLink"
                placeholder="Add Title to generate link"></ion-input> -->
        <ion-button class="grey" slot="end" (click)="copyInviteLink('stage')" [disabled]="!onStageInviteLink">
          {{ stageLinkButtonText }}
        </ion-button>
      </ion-item>

      <div class="shade">
        <ion-item lines="none" class="learn-more-button-container">
          <ion-icon src="assets/icons/24px/sessions.svg" slot="start"> </ion-icon>

          <ion-label class="role-info-button-container">
            {{ stageMembers.length }} / {{ (plan$ | async)?.inStudio }} participants
            <ion-button
              (click)="openRolesInfoMenu($event)"
              tooltip="Learn More About Roles"
              aria-label="Learn More About Roles"
              class="text ion-no-padding">
              <ion-icon src="assets/icons/16px/info-outline.svg"></ion-icon> </ion-button
          ></ion-label>
        </ion-item>

        <ng-select
          #stageSelect
          addTagText="Invite email: "
          [addTag]="addTag"
          placeholder="Enter email or Select a member"
          appearance="outline"
          notFoundText="No one available, add an email?"
          aria-label="Add viewers to backstage. Type email or member name. Select a show to see members from that show."
          [multiple]="true"
          [clearable]="false"
          [selectOnTab]="true"
          (search)="ngSelectSearch($event)"
          (focus)="selectTypingValue = ''"
          (blur)="selectBlur(stageSelect)"
          (add)="addMember($event, 'stage')">
          <ng-option *ngFor="let member of availableMembers" [value]="member">
            <sc-team-member-list-item [member]="member"> </sc-team-member-list-item>
          </ng-option>
        </ng-select>

        <ion-list *ngIf="stageMembers.length" class="viewerList">
          <ion-item *ngFor="let member of stageMembers; last as last" [lines]="last ? 'none' : 'full'">
            <ion-col size-xl="8" size-lg="6" size-md="12">
              <sc-team-member-list-item [member]="member"> </sc-team-member-list-item>
            </ion-col>

            <ion-col size-xl="4" size-lg="6" size-md="12" class="ion-justify-content-end">
              <ion-button
                class="text"
                (click)="participantRemove(member)"
                attr.aria-label="Remove {{ member.email }} from Stage">
                <ion-icon slot="icon-only" src="assets/icons/24px/close.svg"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-item>
        </ion-list>
      </div>

      <div class="backstage" *ngIf="confType !== 'daily'">
        <div class="backstage-title">
          <h4 class="ion-no-margin medium">Backstage</h4>

          <p class="ion-no-margin sub-text" >
            <small> The viewers can watch and listen to the session but only communicate through chat. </small>
          </p>
          <p class="ion-no-margin sub-text">
            <strong> Daily.co Conferences currently does not support Backstage. Will be coming in a future update...</strong>
          </p>
        </div>
        <ion-button *ngIf="!backstageVisible" (click)="createBackstage()" class="line create-backstage-button"
          >Create Backstage</ion-button
        >
      </div>

      <ion-item lines="none" class="copy-link" *ngIf="backstageVisible" (click)="selectInviteLink($event)">
        <ion-icon src="assets/icons/24px/link.svg"></ion-icon>

        <div class="invite-link-url">{{backstageInviteLink}}</div>
        <div *ngIf="!backstageInviteLink" class="placeholder">Add Title to generate link</div>
        <ion-button class="grey" (click)="copyInviteLink('backstage')" [disabled]="!backstageInviteLink" slot="end">
          {{ backstageLinkButtonText }}
        </ion-button>
      </ion-item>

      <div class="shade" *ngIf="backstageVisible">
        <ion-item lines="none" class="learn-more-button-container">
          <ion-icon src="assets/icons/24px/sessions.svg" slot="start"> </ion-icon>

          <ion-label class="role-info-button-container">
            {{ viewerMembers.length }} / {{ (plan$ | async)?.backstage }} viewers
            <ion-button
              (click)="openRolesInfoMenu($event)"
              tooltip="Learn More About Roles"
              aria-label="Learn More About Roles"
              class="text ion-no-padding">
              <ion-icon src="assets/icons/16px/info-outline.svg"></ion-icon> </ion-button
          ></ion-label>
        </ion-item>

        <ng-select
          #viewerSelect
          addTagText="Invite email: "
          [addTag]="addTag"
          placeholder="Enter email or Select a member"
          appearance="outline"
          aria-label="Add viewers to backstage. Type email or member name. Select a show to see members from that show."
          notFoundText="No one available, add an email?"
          [multiple]="true"
          [clearable]="false"
          (search)="ngSelectSearch($event)"
          (focus)="selectTypingValue = ''"
          (blur)="selectBlur(viewerSelect)"
          (add)="addMember($event, 'viewer')">
          <ng-option *ngFor="let member of availableMembers" [value]="member">
            <sc-team-member-list-item [member]="member"> </sc-team-member-list-item>
          </ng-option>
        </ng-select>

        <ion-list *ngIf="viewerMembers.length" class="viewerList">
          <ion-item *ngFor="let member of viewerMembers; last as last" [lines]="last ? 'none' : 'full'">
            <ion-col size-xl="8" size-lg="6" size-md="12">
              <sc-team-member-list-item [member]="member"> </sc-team-member-list-item>
            </ion-col>

            <ion-col size-xl="4" size-lg="6" size-md="12" class="ion-justify-content-end">
              <ion-button
                attr.aria-label="Move {{ member.email }} to Stage"
                (click)="moveStage(member)"
                class="secondary ion-padding-end">
                Move to Stage
              </ion-button>

              <ion-button
                class="text"
                (click)="viewerRemove(member)"
                attr.aria-label="Remove {{ member.email }} from Backstage">
                <ion-icon slot="icon-only" src="assets/icons/24px/close.svg"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>
</sc-dashboard-layout>
