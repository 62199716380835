// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.beta.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  beta: true,
  staging: false,
  firebase: {
    apiKey: 'AIzaSyA4O9ljgJLpuMZ5XxeM0a8hiTA8EQcOVIk',
    authDomain: 'squadcast-dev.firebaseapp.com',
    databaseURL: 'https://squadcast-dev.firebaseio.com',
    projectId: 'squadcast-dev',
    storageBucket: 'squadcast-dev.appspot.com',
    imageBucket: 'squadcast-dev-images',
    recordingBucket: 'squadcast-dev-recordings',
    testRecordingBucket: 'squadcast-dev-test-recordings',
    messagingSenderId: '240058596430',
    appId: '1:240058596430:web:db96c79b835b92a2a8571f',
    measurementId: 'G-2FYQQ8LRKZ',
    dynamicLinksURL: 'dev.sqdc.st/studio',
  },
  stripe: {
    publishableKey: 'pk_test_FDQCArVtvsw9urcEiL0ouDbo',
  },
  typesense: {
    nodes: [
      {
        host: 'h9m6fz8k3e0r5j7vp-1.a1.typesense.net',
        port: 443,
        protocol: 'https',
      },
    ],
  },
  auphonic: {
    googleCloudStorage: '2bafES2Ttjo4roa8SHXu9P',
  },
  recaptcha: {
    siteKey: '6LcitNMUAAAAAPKbw0mpcOUbvFN5TCLNeiIVsoXU',
  },
  rollbar: {
    rollbarToken: 'c3f1100362cd4466ab14368b86a12aac',
  },
  slack: {
    xoxbKey: 'xoxb-234361834692-1910750461908-eYEAGjlcYfARssFb8ITkdU8Y',
  },
  descript: {
    webDomain: 'https://staging-web.descript.com',
  },
  dropbox: {
    client_id:
      'sl.A29zvoC0ZLb_lvVh9u-4Kex2p2FmzmoUyLzZHtpqMAyu7Q2cNBOFMqp4LVK4SBhe_LN5aPGuvkhHb54Iwngtwn7nDmtsGnriM64qkWQaoSHspGlSv7SyxxrRScYc3ZrseKdNbBc',
    redirect_uri: 'http://localhost:8100/account/integrations',
    response_type: 'code',
  },
  toastr: {
    timeOut: 10000,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
  },
  // Beta Testing
  microservices: {
    amplion: 'https://amplion-beta-dot-magnetosphere-298400.uc.r.appspot.com',
    bendix: 'https://bendix-beta-dot-magnetosphere-298400.uc.r.appspot.com',
    califone: 'https://califone-beta-dot-magnetosphere-298400.uc.r.appspot.com',
    distatone: 'https://distatone-beta-dot-magnetosphere-298400.uc.r.appspot.com',
    farnsworth: 'https://farnsworth-beta-dot-magnetosphere-298400.uc.r.appspot.com',
    lafayette: 'https://lafayette-beta-dot-magnetosphere-298400.uc.r.appspot.com',
  },
  daily: {
    url: 'https://squadcast.daily.co',
  },
};
